import "./App.css";
import About from "./components/About";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Rooms from "./components/Rooms";
import Gallery from "./components/Gallery";
import ScrollToTop from "./ScrollToTop";
import Contact from "./components/Contact";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <ScrollToTop/>
          <Header/>
          <Routes>
             <Route exact path="/" element={<Home />} />
             <Route path="/about" element={<About/>} />
             <Route path="/rooms" element={<Rooms/>} />
             <Route path="/gallery" element={<Gallery/>}/>
             <Route path="/contact" element={<Contact/>} />
          </Routes>
         <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
