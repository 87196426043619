import React,{useEffect} from "react";
import "./gallery.css";
import { TypeAnimation } from "react-type-animation";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';

// Import plugins
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import AOS from 'aos';
import "aos/dist/aos.css";
export default function Gallery() {

  useEffect(()=>{
    AOS.init({duration:1000})
 },[]);

 const imagesArray = [
  {
    alt: "Modern Building",
    caption: "A stylish modern building exterior.",
    src: "./imgs/rooms/building1.jpg",
  },
  {
    alt: "Air-conditioned Room",
    caption: "Comfortable room with AC.",
    src: "./imgs/rooms/ac4.png",
  },
  {
    alt: "Sacred Statue",
    caption: "A serene and divine statue.",
    src: "./imgs/rooms/god.jpg",
  },
  {
    alt: "Cozy Room",
    caption: "Spacious and cozy room.",
    src: "./imgs/rooms/room4.jpg",
  },
  {
    alt: "Non-AC Room",
    caption: "Simple and affordable room.",
    src: "./imgs/rooms/non-ac.jpg",
  },
  {
    alt: "Garden Pathway",
    caption: "A beautiful pathway in the garden.",
    src: "./imgs/rooms/path1.jpg",
  },
  {
    alt: "Reception Area",
    caption: "Welcoming reception desk.",
    src: "./imgs/rooms/reception1.jpg",
  },
  {
    alt: "Room with Twin Beds",
    caption: "Room setup with twin beds.",
    src: "./imgs/rooms/room2.jpg",
  },
  {
    alt: "Deluxe Room",
    caption: "Elegant deluxe room interior.",
    src: "./imgs/rooms/room3.jpg",
  },
  {
    alt: "Modern Bathroom",
    caption: "Stylish and clean bathroom.",
    src: "./imgs/rooms/bathroom2.jpg",
  },
  {
    alt: "Meeting Room Setup",
    caption: "Conference room for meetings.",
    src: "./imgs/rooms/meeting1.jpg",
  },
  {
    alt: "Conference Meeting",
    caption: "Spacious conference area.",
    src: "./imgs/rooms/meeting.jpg",
  },
  {
    alt: "Event Hall",
    caption: "Large hall for events and functions.",
    src: "./imgs/rooms/hall.png",
  },
  {
    alt: "Greeting Card",
    caption: "Handmade greeting card display.",
    src: "./imgs/rooms/card1.jpg",
  },
  {
    alt: "Luxury Room",
    caption: "Luxury room with modern amenities.",
    src: "./imgs/rooms/room1.jpg",
  }
];


  return (
    <>
      <header className="gallery"> 
      <main>
          <div className="left">
            <h1>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "Gallery",
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={10}
                style={{ fontSize: "2em", display: "inline-block" }}
                repeat={false}
              />
            </h1>
            <p data-aos="fade-down">
              Sri Datta Paduka Nivas is an affordable hotel in Pithapuram,
              Andhra Pradesh, ideally located behind SriPada Sri Vallabha Temple
              in the China Bazar Area. Offering clean and comfortable rooms,
              it's perfect for pilgrims and tourists seeking a peaceful stay
              near local attractions.
            </p>
            <a href="#goto-gallery">View Gallery</a>
          </div>
        </main>
      </header>

      <br id="goto-gallery"/>
      <br/>
      <br/>
      <br/>
      <br/>

      <LightGallery
      speed={500}
      plugins={[lgZoom, lgThumbnail, lgFullscreen]}
      elementClassNames="custom-lightgallery"
    >
      {imagesArray.map((image, index) => (
        <a key={index} href={image.src} data-lg-size="1600-2400">
          <img alt={image.alt} src={image.src}  />
        </a>
      ))}
    </LightGallery>
    </>
  );
}
