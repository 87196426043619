import React,{useEffect} from "react";
import "./Details.css";
import AOS from 'aos';
import "aos/dist/aos.css";
export default function Details() {
  useEffect(()=>{
    AOS.init({duration:1000})
 },[]);
  return (
    <>
      <div className="details">
        <h1>Accommodation Information</h1>
       
        <p data-aos="fade-down">
          Located at a distance of 220 meters from the Sri Pada Shri Vallabha
          Temple. Sri Datta Paduka Nivas offers two, three and four bedded AC
          and non-AC rooms as well as a dormitory hall. Meals and other
          necessities are available nearby.
        </p>
        <p data-aos="fade-down">
          The hotel also offers parking space for your vehicle. They have other
          facilities like CCTV cameras, Hot water, clean drinking water, a lift
          and flexible check-in and check-out time of 24 hours. You get all of
          these at an affordable price.
        </p>
        <p data-aos="fade-dwon">
          For online room booking in Pithapuram, you can contact us at
          YatraDham.Org.
        </p>
        <div className="near-places">
        
        <div className="place" data-aos="fade-down">
            <img src="./imgs/nearplaces/railway.png" alt="Place 1" />
            <div className="place-info">
            <p>Pithapuram Railway Station</p>
            <h2>750 meters</h2>
            </div>
        </div>
        <div className="place" data-aos="fade-down">
            <img src="./imgs/nearplaces/busstand.png" alt="Place 1" />
            <div className="place-info">
            <p>Pithapuram APSRTC bus stand</p>
            <h2>1.7 kilometers</h2>
            </div>
        </div>
        <div className="place" data-aos="fade-down">
            <img src="./imgs/nearplaces/kr.png" alt="Place 1" />
            <div className="place-info">
            <h2>Kakinada Railway Station</h2>
            <p>17.2 kilometers</p>
            </div>
        </div>
        <div className="place" data-aos="fade-down">
            <img src="./imgs/nearplaces/airport.png" alt="Place 1" />
            <div className="place-info">
            <h2>Rajahmundry Airport </h2>
            <p>52.3 kilometers</p>
            </div>
        </div>
      </div>
      </div>
    
      
    </>
  );
}
