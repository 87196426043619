import React, { useState, useEffect } from "react";
import "./contact.css";
import { TypeAnimation } from "react-type-animation";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, phone, email, message } = formData;
    const whatsappMessage = `Hello, my name is ${name}. I would like to request a room. 
Phone: ${phone}
Email: ${email}
Comments: ${message}`;

    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappURL = `https://wa.me/+919959395966?text=${encodedMessage}`;

    window.open(whatsappURL, "_blank");
  };
  return (
    <>
      <header className="contact">
        <main>
          <div className="left">
            <center>
              <h1>
                <TypeAnimation
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    "Contact us",
                    1000, // wait 1s before replacing "Mice" with "Hamsters"
                  ]}
                  wrapper="span"
                  speed={10}
                  style={{ fontSize: "2em", display: "inline-block" }}
                  repeat={false}
                />
              </h1>
            </center>
            <p id="optional">
              Sri Datta Paduka Nivas is an affordable hotel in Pithapuram,
              Andhra Pradesh, ideally located behind SriPada Sri Vallabha Temple
              in the China Bazar Area. Offering clean and comfortable rooms,
              it's perfect for pilgrims and tourists seeking a peaceful stay
              near local attractions.
            </p>
          </div>
          <div className="right">
            <div className="contact-forms-outer">
              <div className="contact-form-container" data-aos="fade-up">
                <form className="contact-form" onSubmit={handleSubmit}>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="message">Comments</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <input type="submit" value="CONTACT NOW" />
                </form>
              </div>
            </div>
          </div>
        </main>
      </header>
    </>
  );
}
