import React, { useEffect } from "react";
import "./Footer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

export default function Footer() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="footer">
      <div className="inner-footer">
        <div className="first">
          <div className="footer-logo">
            <img src="./imgs/logo.png" alt="SDP Logo" />
            <h1>Sri Datta Paduka Nivas</h1>
          </div>
          <p>Embrace Comfort, Discover Tradition at Sri Datta Paduka Nivas.</p>
        </div>
        <div className="third">
            <h2>Location</h2>
            <img src="./imgs/map.png" alt="Map" />
        </div>
        <div className="second">
           <h2>Get In Touch</h2>
           <div className="social-media">
              <a href="https://www.facebook.com/sridattapadukanivas/" target="_blank"><FaFacebook/></a>
              <a href="https://www.instagram.com/sridattapadukanivas/" target="_blank"><FaInstagram /></a>
              <a href="https://www.youtube.com/channel/UCy46g8j633bJ48iL37wZ_Lg" target="_blank"><FaYoutube /></a>
              <a href="mailto:sridattapadukanivas@gmail.com"></a>
            </div> 
        </div>
      </div>
      <hr />
      <div className="copyright">
        <p>© Sri Datta Paduka Nivas. All rights reserved.</p>
      </div>
    </div>
  );
}
