import React,{useEffect} from "react";
import "./revroom.css";
import AOS from 'aos';
import "aos/dist/aos.css";
export default function Room(props) {
  useEffect(()=>{
    AOS.init({duration:1000})
 },[]);
  return (
    <>
      <div className="rev-room">
        <div className="room-image" data-aos="zoom-out">
          <img src={props.image} alt="Room 1" data-aos="zoom-in"/>
        </div>
        <div className="room-info">
          <h2>{props.title}</h2>
          <p>{props.inc1}</p>
          <p>{props.inc2}</p>
          <p>{props.inc3}</p>
          <p>Price: {props.price}</p>
          <a href="#">Book Now</a>
        </div>
      </div>
    </>
  );
}
