import React,{useEffect} from "react";
import "./rooms.css";
import { TypeAnimation } from "react-type-animation";
import AOS from 'aos';
import "aos/dist/aos.css";
import Room from "./Room";
import Revroom from "./Revroom";

export default function Rooms() {
  useEffect(()=>{
    AOS.init({duration:1000})
 },[]);
  return (
    <>
      <header className="rooms">
        <main>
          <div className="left">
            <h1>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "Available Rooms",
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={10}
                style={{ fontSize: "2em", display: "inline-block" }}
                repeat={false}
              />
            </h1>
            <p data-aos="fade-down">
              Sri Datta Paduka Nivas is an affordable hotel in Pithapuram,
              Andhra Pradesh, ideally located behind SriPada Sri Vallabha Temple
              in the China Bazar Area. Offering clean and comfortable rooms,
              it's perfect for pilgrims and tourists seeking a peaceful stay
              near local attractions.
            </p>
            <a href="#rooms-grid">Check for available rooms</a>
          </div>
        </main>
      </header>
      <br id="rooms-grid"/>
      <br/>
      <div className="rooms-grid" >
          <Room image="./imgs/rooms/room2.jpg" title="2 Bed AC Room" inc1="Double Bed " inc2="Attached Let Bath" price="Rs.1,680.00"/>
          <Revroom image="./imgs/rooms/room4.jpg" title="2 Bed Non AC Room" inc1="Double Bed " inc2="Attached Let Bath" price="Rs.1,344.00"/>
          <Room image="./imgs/rooms/non-ac.jpg" title="3 Bed Non AC Room" inc1="Double Bed " inc2="Single Bed" inc3="Attached Let Bath" price="Rs.1,792.00"/>
          <Revroom image="./imgs/rooms/room6.jpg" title="4 Bed AC Room" inc1="Two Double Bed " inc2="Attached Let Bath" price="Rs.2,240.00"/>
          <Room image="./imgs/rooms/ac4.png" title="12 Bed Dormitory Hall" inc1="12 Beds " inc2="Attached Let Bath" price="Rs.5,376.00"/>
      </div>
    </>
  );
}
