import React,{useEffect} from "react";
import Header from "./Header";
import "./Home.css";
import Content from "./Content";
import { TypeAnimation } from "react-type-animation";
import AOS from 'aos';
import "aos/dist/aos.css";
import Details from "./Details";
export default function Home() {
  useEffect(()=>{
    AOS.init({duration:1000})
 },[]);
  return (
    <>
      <header className="home">
        
        <main>
          <div className="left">
            <h1 data-aos="fade-down">
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "Welcome to",
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  "Sri Datta Paduka Nivas",
                  1000,
                ]}
                wrapper="span"
                speed={10}
                style={{ fontSize: "2em", display: "inline-block" }}
                repeat={Infinity}
              />
            </h1>
            <p data-aos="fade-down">
              Sri Datta Paduka Nivas is an affordable hotel in Pithapuram,
              Andhra Pradesh, ideally located behind SriPada Sri Vallabha Temple
              in the China Bazar Area. Offering clean and comfortable rooms,
              it's perfect for pilgrims and tourists seeking a peaceful stay
              near local attractions.
            </p>
            <a href="https://wa.me/+919959395966?text=Hello,%20I%20would%20like%20to%20request%20a%20room." target="_blank">Check In Now</a>

          </div>
        </main>
      </header>
      
      <Content />
      <Details />
    </>
  );
}
