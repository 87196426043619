import React,{useEffect} from "react";
import "./Content.css";
import AOS from 'aos';
import "aos/dist/aos.css";
export default function Content() {
  useEffect(()=>{
    AOS.init({duration:1000})
 },[]);
  return (
    <>
      <div className="content1">
        <h1>Sri Datta Paduka Nivas</h1>
        <br />
        <br />
        <i data-aos="fade-down">
          <b>
            <i>" </i>
          </b>
          Embrace Comfort, Discover Tradition at Sri Datta Paduka Nivas
          <b>
            <i> "</i>
          </b>
        </i>
        <br />
        <br />
        <br />
        <p data-aos="fade-down">
          Sri Datta Paduka Nivas is a budget-friendly hotel offering a
          comfortable and serene stay in Pithapuram, Andhra Pradesh.
          Conveniently located at 10, 1-85, Backside of SriPada Sri Vallabha
          Temple, in the historic China Bazar Area, this hotel provides easy
          access to one of the region's most revered spiritual sites. Guests can
          enjoy clean and well-maintained rooms at affordable prices, making it
          an excellent choice for pilgrims and tourists alike. With its prime
          location on Sontivari Street, Sri Datta Paduka Nivas ensures a
          peaceful retreat while being close to local attractions and amenities.
        </p>
        <br />
        <br />
        <br />
        <h2 data-aos="fade-down">Book from yatradham.org and get flexible check-in check-out</h2>
        <br />
        <p data-aos="fade-down">
          Check in at any time and stay for the next 24 hours. Arrive and depart
          on your schedule, ensuring convenience and ease during your stay.
        </p>
        <br />
        <br/>
        <a href="https://wa.me/+919959395966?text=Hello,%20I%20would%20like%20to%20request%20a%20room." target="_blank">Book Now</a>
      </div>
    </>
  );
}
