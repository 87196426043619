import React, { useState, useEffect } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`${isScrolled ? 'scrolled' : ''} ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <NavLink to='/'>
          <img src="./imgs/logo.png" alt="SDP-Logo" />
        </NavLink>
        <div className={`menu-icon ${isMobileMenuOpen ? 'close-icon' : ''}`} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <div className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <NavLink 
            to='/rooms' 
            onClick={toggleMobileMenu} 
            className={({ isActive }) => isActive ? 'active' : 'inactive'}
          >
            ROOMS
          </NavLink>
          <NavLink 
            to='/gallery' 
            onClick={toggleMobileMenu} 
            className={({ isActive }) => isActive ? 'active' : 'inactive'}
          >
            GALLERY
          </NavLink>
          <NavLink 
            to='/about' 
            onClick={toggleMobileMenu} 
            className={({ isActive }) => isActive ? 'active' : 'inactive'}
          >
            ABOUT
          </NavLink>

          <NavLink 
            to='/contact' 
            onClick={toggleMobileMenu} 
            className={({ isActive }) => isActive ? 'active' : 'inactive'}
          >
            CONTACT
          </NavLink>
      
        </div>
      </nav>
    </>
  );
}
